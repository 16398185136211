import React from "react"

import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const CaseContainer = ({ siteTitle }) => {
  const data = useStaticQuery(graphql`
    query {
      allSanityEmployees(filter: { _id: { eq: "employees" } }) {
        edges {
          node {
            employee {
              name
              mail
              phone
              position
              image {
                asset {
                  fluid(maxWidth: 230) {
                    ...GatsbySanityImageFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  return (
    <div className="employee_wrapper">
      <p className="selector"></p>

      {data.allSanityEmployees.edges.map(function(employees) {
        return (
          <div className="employee_container">
            {employees.node.employee.map(function(employee) {
              return (
                <div className="employee animation--fade-up animation">
                  <Img
                    className="employee_photo"
                    fluid={employee.image.asset.fluid}
                    alt={employee.name}
                  />
                  <div className="contact_content">
                    <h3>{employee.name}</h3>
                    <div className="content_wrapper">
                      {employee.position.map(position => {
                        return <p className="position">{position}</p>
                      })}
                    </div>
                    <div className="contact_wrapper">
                      <div>
                        {employee.phone && (
                          <a className="phone" href={`tel:${employee.phone}`}>
                            +45 {employee.phone}
                          </a>
                        )}
                      </div>
                      <div>
                        <a className="email" href={`mailto:${employee.mail}`}>
                          {employee.mail}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        )
      })}
    </div>
  )
}

export default CaseContainer
